<template>
  <div class="teacher-container">
    <div class="content-wrap">
      <div class="params">
        <div class="left">
          <el-input
            placeholder="请输入资源名称"
            v-model="queryParams.KeyWord"
            size="large"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="initialize"
            ></el-button>
          </el-input>
        </div>
        <div class="flex">
          <el-button
            type="primary"
            size="large"
            icon="el-icon-plus"
            @click="handleAdd"
            >添加资源</el-button
          >
        </div>
      </div>
      <div class="table-container">
        <div class="table-content">
          <el-table
            v-loading="loading"
            :data="dataList"
            height="100%"
            @selection-change="handleSelectionChange"
          >
            <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
            <template v-for="(item, i) in tableConfig">
              <el-table-column
                :key="i"
                :label="item.label"
                :align="item.align"
                :min-width="item.width"
                :prop="item.prop"
              >
                <template slot-scope="scope">
                  <span v-if="item.prop == 'Oprate'">
                    <!-- <el-button type="text" size="medium" @click="handleOprateAccount(scope.row)">{{getStatus(scope.row['UserStatus']).oprateBtnText}}</el-button> -->
                    <el-button
                      type="text"
                      size="medium"
                      @click="handleView(scope.row.Id)"
                      >查看</el-button
                    >
                    <el-button
                      type="text"
                      size="medium"
                      @click="handleEdit(scope.row.Id)"
                      >修改</el-button
                    >
                    <el-button
                      type="text"
                      size="medium"
                      @click="handleDelete(scope.row)"
                      >删除</el-button
                    >
                  </span>
                  <span class="info" v-else-if="item.prop == 'Name'">
                    <span class="ml-16">{{ scope.row[item.prop] }}</span>
                  </span>
                  <span class="info" v-else-if="item.prop == 'Description'">
                    <span
                      class="ml-16 Description"
                      v-html="scope.row[item.prop]"
                    ></span>
                  </span>
                  <span class="info" v-else-if="item.prop == 'IsTeacher'">
                    <span class="ml-16">{{
                      scope.row[item.prop] ? "是" : "否"
                    }}</span>
                  </span>
                  <span v-else-if="item.prop == 'UserStatus'">
                    <span
                      class="account-status"
                      :style="{
                        color: getStatus(scope.row[item.prop]).color,
                        background: getStatus(scope.row[item.prop]).bgc
                      }"
                    >
                      {{ scope.row["UserStatus_V"] }}</span
                    >
                  </span>
                  <span v-else>
                    {{ scope.row[item.prop] }}
                  </span>
                </template>
              </el-table-column>
            </template>
          </el-table>
        </div>
      </div>
      <div class="pag">
        <pagination
          layout="prev, pager, next"
          v-show="total > 0"
          :total="total"
          :page.sync="queryParams.PageIndex"
          :limit.sync="queryParams.PageSize"
          @pagination="getList"
        >
        </pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import store from "@/store";
import { getPageList, deleteEntity } from "@/api/enterprise/resourceInvestment";
export default {
  components: {
    Pagination
  },
  props: {},
  data() {
    return {
      loading: false,
      tableConfig: [
        { label: "资源名", prop: "Name", width: 80 },
        { label: "发布时间", prop: "PublishTime", width: 80 },
        { label: "内容", prop: "Description", width: 200 },
        { label: "操作", prop: "Oprate", width: 120 }
      ],
      dataList: [],
      queryParams: {
        EnterpriseId: "",
        KeyWord: "",
        OwnerType: null,
        PageSize: 10,
        PageIndex: 1
      },
      userList: [],
      total: 0,
      ownerTypeOptions: [
        {
          value: null,
          label: "全部状态"
        }
      ]
    };
  },
  watch: {},
  created() {
    this.initialize();
  },
  mounted() {},
  methods: {
    initialize() {
      this.queryParams.PageIndex = 1;
      this.getList();
    },
    async getList() {
      try {
        this.loading = true;
        this.queryParams.EnterpriseId = store.getters.employee[0].EmployerId;
        const res = await getPageList(this.queryParams);
        this.dataList = res.Result.Data;
        this.total = res.Result.TotalCount || 0;
      } catch (error) {
        console.log("error: ", error);
      } finally {
        this.loading = false;
      }
    },
    handleDelete({ Id, Name }) {
      this.$confirm(`确定要删除[${Name}]吗?删除后将无法恢复！`, "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          try {
            this.loading = true;
            const res = await deleteEntity(Id);
            if (res.IsSuccess) {
              this.$message.success("删除成功");
              this.getList();
            } else {
              this.$message.error(res.Message || "删除失败");
            }
          } catch (error) {
            console.log("error: ", error);
          } finally {
            this.loading = false;
          }
        })
        .catch(err => {
          console.error(err);
        });
    },
    handleSelectionChange() {},
    handleAdd() {
      this.$router.push("/enterprise/resourceInvestment/create");
    },
    handleEdit(id) {
      this.$router.push("/enterprise/resourceInvestment/edit?id=" + id);
    },
    handleView(id) {
      this.$router.push("/enterprise/resourceInvestment/view?id=" + id);
    },
    handleImport() {
      this.$router.push("/enterprise/resourceInvestment/import");
    }
  }
};
</script>

<style lang="scss" scoped>
.teacher-container {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: space-between;
  .content-wrap {
    box-sizing: border-box;
    // padding: 24px;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    .header {
      display: inline-flex;
      align-items: center;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      color: #999999;
      .title {
        font-size: 18px;
        color: #666666;
        line-height: 25px;
      }
    }
    .params {
      margin: 16px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        display: flex;
        align-items: center;
        .label {
          white-space: nowrap;
        }
      }
    }
    .table-container {
      flex: 1;
      height: 100%;
      position: relative;
      .table-content {
        position: absolute;
        width: 100%;
        height: 100%;
        .info {
          display: inline-flex;
          align-items: center;
          .Description {
            display: inline-flex;
            min-width: 90px;
            height: 51px;
            white-space: nowrap; /*一行显示*/
            overflow: hidden; /*超出部分隐藏*/
            text-overflow: ellipsis; /*用...代替超出部分*/
          }
        }
        .account-status {
          padding: 4px 5px;
          border-radius: 14px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
        }
      }
    }
  }
  .btn-delete {
    color: #ff6600;
    border-color: #ff6600;
    &:hover {
      color: #f56c6c;
      border-color: #f56c6c;
      background: rgba($color: #f56c6c, $alpha: 0.1);
    }
  }
  .is-disabled {
    color: #c0c4cc;
    cursor: not-allowed;
    background-image: none;
    background-color: #ffffff;
    border-color: #e6ebf5;
    &:hover {
      color: #c0c4cc;
      background-color: #ffffff;
      border-color: #e6ebf5;
    }
  }
}

// 分页
.pag {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
